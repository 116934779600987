import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ImageStorageService {
    constructor(private http: HttpClient) {
    }
    uploadImage(fileImage, directory: string, timestamp): Observable<any> {
        let httpOpt = {
            headers: new HttpHeaders({
                //'Access-Control-Allow-Origin': 'http://artistic.ideassolidas.co/',
                //'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
                'Access-Control-Max-Age': '86400'
            })
        };

        let formData = new FormData();
        formData.append('file', fileImage);
        formData.append('directoryName', directory != undefined ? directory.replace('/', '') : '');
        formData.append('timestamp', timestamp);
        return this.http.post(environment.apiGoogleStorage + '/uploadimagedirectory/', formData, httpOpt);
    }

    createDirectory(name: any): Observable<any> {
        let httpOpt = {
            headers: new HttpHeaders({
                //'Access-Control-Allow-Origin': 'http://artistic.ideassolidas.co/',
                //'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
                'Access-Control-Max-Age': '86400'
            })
        };

        return this.http.post(environment.apiGoogleStorage + '/directory/', { directoryName: name }, httpOpt);
    }

    renameDirectory(name: any, rename: any): Observable<any> {
        let httpOpt = {
            headers: new HttpHeaders({
                //'Access-Control-Allow-Origin': 'http://artistic.ideassolidas.co/',
                //'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
                'Access-Control-Max-Age': '86400'
            })
        };

        return this.http.post(environment.apiGoogleStorage + '/renamedirectory/', { sourceFolderName: name, destFolderName: rename }, httpOpt);
    }

    getImagesList(): Observable<any> {
        let httpOpt = {
            headers: new HttpHeaders({
                //'Access-Control-Allow-Origin': 'http://artistic.ideassolidas.co/',
                //'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
                'Access-Control-Max-Age': '86400'
            })
        };

        return this.http.get(environment.apiGoogleStorage + '/listFiles/', httpOpt);
    }

}
