import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormGroup, FormControl } from '@angular/forms';
import { ImageStorageService } from '../../../@core/backend/common/api/image-storage.api';


@Component({
  selector: 'ngx-new-file-dialog',
  templateUrl: './new-file-dialog.component.html',
  styleUrls: ['./new-file-dialog.component.scss']
})
export class NewFileDialogComponent implements OnInit {

  constructor(public propertiesService: ImageStorageService, public dialogRef: NbDialogRef<NewFileDialogComponent>) { }

  formImageManager: FormGroup;
  imageOption: any;
  currentPath: string;

  ngOnInit() {
    this.formImageManager = new FormGroup({
      pathBasePictureOption: new FormControl()
    });
  }

  onImageOptionChanged(event: any) {
    this.imageOption = event.target.files[0];

  }
  cancel() {
    this.dialogRef.close();
  }

  submit() {
    const timestamp = new Date().getTime().toString();
    this.propertiesService.uploadImage(this.imageOption, this.currentPath, timestamp).subscribe(
      res => {
        //setTimeout(function (){
        this.dialogRef.close(res.ffname);
        //}, 2000);
      }
    );
  }
}

