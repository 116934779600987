import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ImageStorageService } from '../../../@core/backend/common/api/image-storage.api';
import { FileService } from '../../service/file.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';

@Component({
  selector: 'ngx-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent implements OnInit {

  constructor(public fileService: FileService,
    private toastrService: NbToastrService, public propertiesService: ImageStorageService, public dialogRef: NbDialogRef<RenameDialogComponent>) { }

  @Input() folderCurrentName: string;
  folderName: string;

  ngOnInit() { }
  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (this.fileService.folderId(this.folderName) == null) {
      this.propertiesService.renameDirectory(this.folderCurrentName, this.folderName).subscribe(
        res => {
          if (res) {
            this.dialogRef.close(this.folderName);
          }
        }
      );
    }else{
      this.toastrService.show("File name already exists", "Folder name", { status: NbToastStatus.WARNING });
    }
  }
}
