import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ImageStorage } from '../../@core/interfaces/common/project';
import { NbDialogRef } from '@nebular/theme';
import { ImageStorageService } from '../../@core/backend/common/api/image-storage.api';

@Component({
  selector: 'ngx-imanager',
  templateUrl: './imanager.component.html',
  styleUrls: ['./imanager.component.scss'],
})
export class ImanagerComponent implements OnInit {
  formImageManager: FormGroup;
  imagesData: ImageStorage = new ImageStorage();
  imageOption: any;
  imgTemp: string;
  currentPath: string = '';
  message: string = '';

  constructor(public propertiesService: ImageStorageService, protected dialogRef: NbDialogRef<any>) {
  }

  ngOnInit() {
    this.getImages();
    this.formImageManager = new FormGroup({
      pathBasePictureOption: new FormControl()
    });
  }

  getFullPath(imageItem) {
    let cad = this.imagesData.base_url.lastIndexOf('?');
    if (cad == -1) { 
      return this.imagesData.base_url + imageItem;
    } else {
      let fullPath = this.imagesData.base_url.substr(0, cad);
      let cad2 = fullPath.lastIndexOf('/');
      let fullPath2 = this.imagesData.base_url.substr(0, cad2);
      return  fullPath2 + '/' + imageItem;
    }
  }

  onImageOptionChanged(event: any) {
    this.imageOption = event.target.files[0];
    let timestamp = new Date().getTime().toString();
    this.message = 'Processing image...';
    this.propertiesService.uploadImage(this.imageOption, this.currentPath, timestamp).subscribe(
      res => {
        if (res) {
          this.message = 'Image processed';
          let indexA = res.base_url.lastIndexOf( '?' );
          if (indexA >= 0) {
            let RutaAbsoluta = res.base_url.substring(0, indexA);
            this.dialogRef.close(RutaAbsoluta);
          } else {
            let RutaAbsoluta = res.base_url.substring(0, res.base_url.length - 1);
            this.dialogRef.close(RutaAbsoluta + res.ffname);
          }
        } else {
          this.message = res;
        }
      }
    );
  }

  selectImage(imageItem: string) {
    let cad = this.imagesData.base_url.lastIndexOf('?');
    if (cad == -1) { 
      this.dialogRef.close(this.imagesData.base_url + imageItem);
    } else {
      let fullPath = this.imagesData.base_url.substr(0, cad);
      let cad2 = fullPath.lastIndexOf('/');
      let fullPath2 = this.imagesData.base_url.substr(0, cad2);
      this.dialogRef.close(fullPath2 + '/' + imageItem);
    }
  }

  getImages() {
    this.propertiesService.getImagesList().subscribe(
      result => {
        this.imagesData = result;
      }
    );
    this.imagesData.files.sort((one, two) => (one > two ? -1 : 1));
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
