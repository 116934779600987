import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ImageStorageService } from '../../../@core/backend/common/api/image-storage.api';
import { FileElement } from '../../model/element';
import { FileService } from '../../service/file.service';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';

@Component({
  selector: 'ngx-new-folder-dialog',
  templateUrl: './new-folder-dialog.component.html',
  styleUrls: ['./new-folder-dialog.component.scss']
})
export class NewFolderDialogComponent implements OnInit {

  constructor(public fileService: FileService,
    private toastrService: NbToastrService, public propertiesService: ImageStorageService, public dialogRef: NbDialogRef<NewFolderDialogComponent>) { }

  @Input() folderName: string;
  public FolderCurrent: any[];
  ngOnInit() { }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    if (this.fileService.folderId(this.folderName) == null) {
      this.propertiesService.createDirectory(this.folderName).subscribe(
        res => {
          if (res) {
            this.dialogRef.close(this.folderName);
          }
        }
      );
    }else{
      this.toastrService.show("File name already exists", "Folder name", { status: NbToastStatus.WARNING });
    }
  }
}

