import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import { FileElement } from '../model/element';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

interface IFileService {
  add(fileElement: FileElement);
  delete(id: string);
  deleteAll();
  update(id: string, update: Partial<FileElement>);
  queryInFolder(folderId: string): Observable<FileElement[]>;
  get(id: string): FileElement;
}

@Injectable()
export class FileService implements IFileService {
  private map = new Map<string, FileElement>();

  constructor() {
    this.map = new Map<string, FileElement>();
    // console.log(this.map);
   }

  add(fileElement: FileElement) {
    fileElement.id = v4();
    this.map.set(fileElement.id, this.clone(fileElement));
    return fileElement;
  }

  deleteAll() {
    this.map = new Map<string, FileElement>();
  }

  delete(id: string) {
    this.map.delete(id);
  }

  folderId(name: string) {
    let elementId = null;
    this.map.forEach(element => {
      if (element.name === name) {
        elementId = element.id;
      }
    })
    return elementId;
  }

  update(id: string, update: Partial<FileElement>) {
    let element = this.map.get(id);
    element = Object.assign(element, update);
    this.map.set(element.id, element);
  }

  private querySubject: BehaviorSubject<FileElement[]>;
  queryInFolder(folderId: string) {
    let result: FileElement[] = [];
    this.map.forEach(element => {
      if (element.parent === folderId) {
        result.push(this.clone(element));
      }
    });
    result = result.sort(function (a, b) {
      return a.codigo - b.codigo;
    });
    if (!this.querySubject) {
      this.querySubject = new BehaviorSubject(result);
    } else {
      this.querySubject.next(result);
    }
    return this.querySubject.asObservable();
  }

  get(id: string) {
    return this.map.get(id);
  }

  clone(element: FileElement) {
    return JSON.parse(JSON.stringify(element));
  }
}